<template>
  <div>
    <div class="card mb-4 custom-card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-9 row">
            <div class="col-sm-2">
                <date-picker style="max-width:180px;" :format="'DD/MM/YYYY'" v-model="criteria.date_order"  input-class="form-control" placeholder="วันที่สั่งซื้อ" :append-to-body="false" @change="searchHandler"></date-picker>
            </div>
            <div class="col-sm-2">
                <date-picker style="max-width:180px;" :format="'DD/MM/YYYY'" v-model="criteria.date_delivery"  input-class="form-control" placeholder="วันที่จัดส่ง" :append-to-body="false" @change="searchHandler"></date-picker>
            </div>
            <div class="col-sm-2">
                <date-picker style="max-width:180px;" :format="'DD/MM/YYYY'" v-model="criteria.date_issued"  input-class="form-control" placeholder="วันที่แจ้งปัญหา" :append-to-body="false" @change="searchHandler"></date-picker>
            </div>
            <div class="col-sm-3">
                <select class="form-control"  v-model="criteria.store" @change="searchHandler" placeholder="ร้านค้า">
                  <option value="">ทุกร้านค้า</option>
                  <option v-for="option in cbbOptions.store" :value="option.id" :key="option.id">{{option.id + ' : ' + option.name}}</option>
                </select>
            </div>
            <div class="col-sm-3">
              <Select2Supplier :value="criteria.supplier" ref="Select2Supplier" @change="getSupplierById"></Select2Supplier>
            </div>
          </div>
          <div class="col-sm-3">
            <div>
              <b-input-group>
                <b-input-group-append><b-input-group-text class="input_border_radius_left_top_bt"><b-icon icon="search" /></b-input-group-text></b-input-group-append>
                <b-form-input placeholder="ระบุการค้นหา เช่น ส่วนลดให้ลูกค้า /ยอดโอนคืน /เพิ่ม/ลดเงินซัพ" v-model="criteria.search" class="form-control-group" @keyup.enter.native="searchHandler()"></b-form-input>
                <b-input-group-append><b-button  size="md" text="Button" variant="primary" @click="searchHandler()" class="btn_border_radius_right_top_bt" style="width: 86px;">ค้นหา</b-button></b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card custom-card mb-4">
      <div class="card-body">
        <div class="row">
            
        </div>
        <div class="table-responsive padding_top_20">
          <table class="table align-middle table-bordered">
            <thead>
              <tr class="text-center" >
                <th>ดำเนินการ</th>
                <th>Order Date</th>
                <th>Order Created</th>
                <th>Order Code</th>
                <th>Store</th>
                <th>ซัพพลายเออร์</th>
                <th>ประเภทปัญหา</th>
                <th>สาเหตุปัญหา</th>
                <th>Delivery Location</th>
                <th>Delivery Address</th>
                <th>Product Item Code</th>
                <th>รายละเอียดปัญหา</th>
                <th>การแก้ไข</th>
                <th>ส่วนลดให้ลูกค้า</th>
                <th>เพิ่ม/ลดเงินซัพ</th>
                <th>ความเสียหายอื่น</th>
                <th>ยอดรวม</th>
                <th>ยอดโอนคืน</th>
                <th>หมายเหตุ</th>
                <th>Delivery Time</th>
                <th>ผู้แจ้งปัญหา</th>
                <th>สถานะปัญหา</th>
              </tr>
            </thead>
            <tbody v-if="!onSearching && items.length != 0">
              <tr v-for="(data) in items" :key="`list_issue_${data.id}`">
                <!--<td class="text-center">{{data.source==1?"Order":data.source==2?"Payment":data.source==3?"Delivery":""}}</td>-->
                <td class="text-center" >
                  <span class="style_icon_btn" v-if="permission.actionData.flag_update">
                    <i class="fas fa-pen" @click="onEditHandler(data.id)" title="แก้ไข"></i>
                  </span>
                  <span class="style_icon_btn" v-if="permission.actionData.flag_update">
                    <i class="fas fa-edit" @click="onEditHandlerResolve(data.id)" title="การแก้ปัญหา"></i>
                  </span>
                  <span class="style_icon_btn" v-if="permission.actionData.flag_update">
                    <i class="fas fa-clipboard-check" title="ยืนยันการแก้ปัญหา" @click="onApproveHandler(data)"></i>
                  </span>
                </td>
                <td>{{data.order_date}}</td>
                <td>{{data.order_created}}</td>
                <td>{{data.order_code}}</td>
                <td>{{data.store_name}}</td>
                <td>{{data.supplier_name}}</td>
                <td>{{data.type_name}}</td>
                <td>{{getCauseTypeName(data.status)}}</td>
                <td>{{data.delivery_location}}</td>
                <td>{{data.delivery_address}}</td>
                <td>{{data.id}}</td>

                <td>{{data.topic}}</td>
                <td class="save_space" :title="data.solution">{{data.solution}}</td>
                <td>{{data.comp_customer_discount	}}</td>
                <td>{{data.comp_supplier_discount}}</td>
                <td>{{data.comp_supplier_discount}}</td>
                <td>{{data.comp_total}}</td>
                <td>-</td>
                <td class="save_space" :title="data.remark">{{data.remark}}</td>
                <td>{{data.expected_delivery_time}}</td>
                <td>{{data.user_issuer_name}}</td>
                <td>
                  <span v-if="data.cause_id == 1"><i class="fas fa-exclamation-circle text-warning"></i> "เกิดปัญหาขึ้น แล้วแจ้งเป็น issue ขึ้นมา"</span>
                  <span v-if="data.status == 2"><i class="fas fa-check text-primary"></i> "เมื่อแก้ไขปัญหานั้น ๆ แล้ว"</span>
                  <span v-if="data.status == 3"><i class="fas fa-check-circle text-success"></i> "ผ่านการตรวจสอบ"</span>
                  <span v-if="data.status == 4"><i class="fas fa-times-circle text-danger"></i> "ไม่ผ่านการตรวจสอบ"</span>
                </td>
              </tr>
              <!-- <tr v-if="items.length === 0" class="text-center">
                <td colspan="7">ไม่มีข้อมูล</td>
              </tr> -->
            </tbody>
            <tbody v-if="!onSearching && items.length == 0">
              <tr class="text-center">
                <td colspan="10">ไม่มีข้อมูล</td>
              </tr>
            </tbody>
            <tbody v-if="onSearching">
              <tr class="text-center">
                <td colspan="10"><div class="spinner"></div></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card mb-4 mt-4 custom-card">
      <div class="card-body ">
        <div class="row">
          <div class="col-md-6">
            <h3>สถานะปัญหา</h3>
            <i class="fas fa-exclamation-circle text-warning"></i> 1 "เกิดปัญหาขึ้น แล้วแจ้งเป็น issue ขึ้นมา"<br>
            <i class="fas fa-check text-primary"></i> 2 "เมื่อแก้ไขปัญหานั้น ๆ แล้ว"<br>
            <i class="fas fa-check-circle text-success"></i> 3 "ผ่านการตรวจสอบ"<br>
            <i class="fas fa-times-circle text-danger"></i> 4 "ไม่ผ่านการตรวจสอบ"<br>
          </div>
        </div>
      </div>
    </div>
      <div class="row padding_top_20">
        <div  class="col-lg-1 col-md-2 col-3"
              style="margin-top: 30px;">
          <select class="form-control mb-2"
                  id="pageLimit"
                  style="width: 80px;"
                  v-model="paging.limit" v-on:change="changeLimit()">
            <option v-for="(data) in paging.pages"
                    :key="data"
                    :value="data">
              {{data}}
            </option>
          </select>
        </div>
        <div class="col-lg-11">
          <div class="row g-3"  v-if="paging.rowTotal > 0">
            <div  class="col-xl-11 col-lg-10 mb-2 text-right"
                  style="margin-top: 30px;">
              <label  class="form-label"
                      style="margin: 8px; font-size: 12px;
                      font-family: Sarabun-Light;
                      color: #818181;">
                แสดงรายการที่ <strong>{{((paging.page-1)*paging.limit)+1}} ถึง {{(paging.page) * paging.limit}}</strong> จาก <strong>{{paging.rowTotal | numberIntFormat}} รายการ </strong>
              </label>
              <paginate v-model="paging.page"
                        :first-last-button="true"
                        :page-count="paging.pageTotal"
                        :page-range="5"
                        :margin-pages="2"
                        :prev-text="'&lt;'"
                        :next-text="'&gt;'"
                        :first-button-text="'&verbar;&lt;'"
                        :last-button-text="'&gt;&verbar;'"
                        :click-handler="changePage"
                        :container-class="'pagination pagination-sm float-right m-auto paging_ul'"
                        :page-class="'page-link'"
                        :prev-class="'page-link prev_paging'"
                        :next-class="'page-link next_paging'">
              </paginate>
            </div>
            <div class="col-xl-1 col-lg-2 padding_left_none">
              <label  class="form-label" style="font-size: 12px; font-family: Sarabun-Light;"> Go to page </label>
              <Select2 v-model="paging.page" :options="paging.pageSizes" @change="changePage(paging.page)"/>
            </div>
          </div>
        </div>
      </div>
    <dialog-confirmed-resolve :item="item" @completed="initialData" />
  </div>
</template>
<script>
  import dialogConfirmedResolve from './dialog.confirmed.resolve';
  import Config from '../../../util/config';
  import Constants from '../../../util/constants';
  import Select2Supplier from "../../supplier/component/select2.supplier";
  import DateUtils from "../../../util/dateUtils";
  export default {
    name: 'issue-view-page-issue',
    data() {
      return {
        permission:{
          appSlug: 'issue',
          actionData: {}
        },
        cbbOptions : {
          store : [],
          supplier : []
        },
        criteria: {
          date_order: "",
          date_delivery: "",
          date_issued: "",
          store: "",
          supplier: "",
          search: "",
          orderBy: "",
          ascDesc: ""
        },
        item: {},
        items: [],
        paging: {
          page: 1,
          limit: Config.pagination.limit,
          pages: Config.pagination.pages,
          rowTotal: 0,
          pageTotal: 0,
          pageSizes: []
        },
        issueTypes: [],
        statusTypes: Constants.issue_status,
        issueCauseTypes: Constants.issue_causes,
        onSearching: true
      };
    },
    methods: {
      async getSupplierById(supplier_id) {
        this.criteria.supplier =supplier_id;
        this.getListItems();
      },
      onEditHandler(id){
        this.SessionStorageUtils.setSession("page-issue", id);
        this.$router.push(`/issue/edit/${id}`);
      },
      onEditHandlerResolve (id) {
        this.SessionStorageUtils.setSession("page-issue", id);
        this.$router.push(`/issue/resolve/${id}`);
      },
      onApproveHandler (data) {
        this.item = JSON.parse(JSON.stringify(data));
        this.$bvModal.show('dialog_confirmed_resolve');
      },

      /******* local function ******/
      changeLimit(){
        this.paging.page = 1;
        this.getListItems();
      },
      changePage (page) {
        this.paging.page = parseInt(""+page);
        this.getListItems();
      },
      sortHandler(orderBy){
        if(orderBy!=this.criteria.orderBy){
          this.criteria.ascDesc = "asc";
        }else{
          this.criteria.ascDesc = this.criteria.ascDesc=="asc"?"desc":"asc";
        }
        this.criteria.orderBy = orderBy;
        this.getListItems();
      },
      searchHandler(){
        this.paging.page = 1;
        this.getListItems();
      },
      async getListItems () {
        let params = `search=${this.criteria.search}`;
        params += `&page=${this.paging.page}&limit=${this.paging.limit}&orderBy=${this.criteria.orderBy}&ascDesc=${this.criteria.ascDesc}`;
        params += `&date_order=` + DateUtils.dateFormat(this.criteria.date_order,"YYYY-MM-DD");
        params += `&date_delivery=` + DateUtils.dateFormat(this.criteria.date_delivery,"YYYY-MM-DD");
        params += `&date_issued=` + DateUtils.dateFormat(this.criteria.date_issued,"YYYY-MM-DD");
        params += `&store_id=${this.criteria.store}`;
        params += `&supplier_id=${this.criteria.supplier}`;
        this.onSearching = true;
        const result = await this.HttpServices.getData(`/ab_issue?${params}`);
        this.onSearching = false;
        if(result&&result.status.code=="200"){
          const data = result.data;
          var total = Math.ceil(data.rowTotal/this.paging.limit);
          this.paging.rowTotal = data.rowTotal;
          this.paging.pageTotal = total;
          this.items = data.data;
          this.paging.pageSizes = [];
          for(let i=1;i<=total;i++){
            this.paging.pageSizes.push({id: i, text: i});
          }
        }else{
          this.paging.pageSizes = [];
          this.paging.rowTotal = 0;
          this.items = [];
        }
      },
      checkDisabledDelete(){
        let resp = true;
        for(const v of this.items){
          if(v.selected){
            resp = false;
            break;
          }
        }
        return resp;
      },
      async initialData(){
        this.getListItems();
      },
      getStatusIssueName (status) {
        const result = this.statusTypes.find(v=>v.id==status);
        return result ? result.text : '';
      },
      getIssueTypeName (type) {
        const result = this.issueTypes.find(v=>v.id==type);
        return result ? result.text : '';
      },
      getCauseTypeName (type) {
        const result = this.issueCauseTypes.find(v=>v.id==type);
        return result ? result.text : '';
      },
      async getIssueType () {
        this.issueTypes = await this.HttpServices.getMasterData(`/master/getIssueTypes`);
      },
      async getStore () {
        const data = await this.HttpServices.getMasterData(`/ab_store/`);
        this.cbbOptions.store = data.data.data;
      }
      /******* local function ******/
    },
    components: {
      dialogConfirmedResolve,
      Select2Supplier
    },
    async mounted() {
      await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
      this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
      await this.getIssueType();
      await this.getStore();
      await this.initialData();
      this.SessionStorageUtils.deleteSession("page-issue");
    }
  };
</script>
<style scope>
  .checkbox_style > .custom-control {
    left: 12px;
  }

  .save_space{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }

/*
tr>th:first-child,tr>td:first-child {
  position: sticky;
  left: 0;
  background-color: #fff;
}
*/

tr>th:nth-child(1),tr>td:nth-child(1) {
  position: sticky;
  left: 0;
  min-width: 100px;
  background-color: #fff; 
}

tr>th:nth-child(2),tr>td:nth-child(2) {
  position: sticky;
  left: 107px;
  min-width: 50px;
  background-color: #fff; 
}

tr>th:nth-child(3),tr>td:nth-child(3) {
  position: sticky;
  left: 244px;
  min-width: 133px;
  background-color: #fff; 
}

tr>th:nth-child(4),tr>td:nth-child(4) {
  position: sticky;
  left: 377px;
  background-color: #fff; 
}

th, td {
  outline: 1px solid #e9ecef;
  outline-offset: -1px;
}
.spinner {
  display: inline-block;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #000;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

</style>
