<template>
  <div>
    <b-modal id="dialog_confirmed_resolve" size="lg">
      <template #modal-title>
        <h3><i class="fas fa-clipboard-check"></i> ยืนยันการแก้ปัญหา</h3>
      </template>
      <div>
        <validation-observer v-slot="{handleSubmit}" ref="formValidator">
          <b-form @submit.prevent="handleSubmit(approveHandler)">
            <div class="mb-4 row">
              <label class="col-sm-4 col-form-label label_algin_right">ผู้อนุมัติ</label>
              <div class="col-sm-8">
                <base-input name="approver" placeholder="ผู้อนุมัติ" v-model="profile.fullname" disabled>
                </base-input>
              </div>
            </div>
            <div class="mb-4 row">
              <label class="col-sm-4 col-form-label label_algin_right m-auto">สถานะการตรวจสอบ</label>
              <div class="col-sm-8">
                <b-form-radio-group v-model="item.status" :options="options" class="mt-4 mb-4" name="approveStatus" text-field="text"></b-form-radio-group>
                <base-input name="status" v-model="item.status" :rules="{required: true}" class="custom-input-valid-datepicker"></base-input>
              </div>
            </div>
            <button ref="submitButton" style="display:none;">Submit</button>
          </b-form>
        </validation-observer>
      </div>
      <template #modal-footer>
        <b-button variant="primary" @click="onOutsideSubmit()" class="style_btn"><i class="fa fa-check"></i> ตกลง</b-button>
        <b-button variant="secondary" @click="close()"><i class="fa fa-times"></i> ยกเลิก</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Constants from '../../../util/constants';
import SessionStorageUtils from '../../../util/sessionStorageUtils';
export default {
  name: 'issue-view-dialog-confirmed-resolve',
  data () {
    return {
      options: Constants.issue_approve_priority,
      profile: {}
    }
  },
  props: ['item'],
  methods: {
    onOutsideSubmit(){
      this.$refs.submitButton.click();
    },
    async approveHandler () {
      const params = {status: this.item.status};
      const result = await this.HttpServices.putData(`/ab_issue/updateApproveStatus/${this.item.id}`, params);
      if(result&&result.status.code=="200"){
        this.$bvModal.hide('dialog_confirmed_resolve');
        this.$emit('completed', true);
        this.AlertUtils.alert('success', `บันทึกสำเร็จ`);
      }else{
        this.AlertUtils.alert('warning', result.status.message);
      }
    },
    close () {
      this.$bvModal.hide('dialog_confirmed_resolve');
    }
  },
  async mounted() {
    this.profile = await SessionStorageUtils.decodeUser();
  },
}
</script>
<style lang="">

</style>
